import React from "react";
import styled from "styled-components";
import MaxWidthSection from "./Layout/MaxWidthSection";
import article from "../assets/article.pdf";

const FeaturedNewsStyles = styled.section`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  background: var(--color-black);
  position: relative;
  .text {
    grid-column-end: span 8;
    position: relative;
    z-index: 10;
    h2,
    p {
      color: var(--color-white);
    }
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column-end: span 6;
      text-align: center;
    }
  }
  .download-article {
    grid-column-end: span 4;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column-end: span 6;
    }
  }
  .corner-left {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 10rem;
    z-index: 0;
    @media ${(props) => props.theme.breakpoints.m} {
      height: 6rem;
    }
  }
`;

const FeaturedNews = () => {
  return (
    <FeaturedNewsStyles>
      <MaxWidthSection>
        <div className="text">
          <h2>Featured News Article</h2>
          <p>
            J&#38;J Investment Properties was recognized in the Albany Business
            Review for its contributions to the community and for providing
            affordable student housing in Albany, NY
          </p>
        </div>
        <div className="download-article">
          <a href={article} download className="btn btn-secondary btn-white">
            Download Article
          </a>
        </div>
      </MaxWidthSection>
      {/* <img src={cornerLeft} alt="corner left" className="corner-left" /> */}
    </FeaturedNewsStyles>
  );
};

export default FeaturedNews;
