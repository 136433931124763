import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import MaxWidthSection from "./Layout/MaxWidthSection";
import parse from "html-react-parser";

const AboutUsSectionStyles = styled.section`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;
  &:last-child {
    padding-bottom: 0;
  }
  background: ${(props) =>
    props.grayBg ? "var(--color-very-light-gray);" : "transparent;"};

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .details {
    grid-column: ${(props) =>
      props.imageLeft ? "7 / span 6;" : "1 / span 6;"};
    grid-row: 1/1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    text-align: ${(props) => (props.imageLeft ? "left;" : "right;")};
    p {
      text-align: justify;
    }

    h2 {
      span {
        color: var(--color-accent);
      }
    }

    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
      grid-row: 2;
      text-align: center;
      align-items: center;
    }
  }

  .image {
    grid-column: ${(props) =>
      props.imageLeft ? "1 / span 6;" : "7 / span 6;"};
    grid-row: 1/1;

    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
      margin-bottom: 2em;
    }
    .gatsby-image-wrapper {
      width: 100%;
    }
  }
`;

const AboutUsSection = ({
  title,
  content,
  image,
  imageLeft,
  grayBg,
  imageLink,
}) => {
  return (
    <AboutUsSectionStyles imageLeft={imageLeft} grayBg={grayBg}>
      <MaxWidthSection>
        <div className="details">
          <h2>{parse(title)}</h2>
          <p>{content}</p>
        </div>
        <div className="image">
          {imageLink ? (
            <a href={imageLink} target="_blank" rel="noopener noreferrer">
              <GatsbyImage image={image} alt="Property Image" />
            </a>
          ) : (
            <GatsbyImage image={image} alt="Property Image" />
          )}
        </div>
      </MaxWidthSection>
    </AboutUsSectionStyles>
  );
};

export default AboutUsSection;
