import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import TopTitleBar from "../components/TopTitleBar";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import MaxWidthSection from "../components/Layout/MaxWidthSection";
import AboutUsSection from "../components/AboutUsSection";
import FeaturedNews from "../components/FeaturedNews";

const AboutUsStyles = styled.main`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  /* section:not(.no-padding):not(.row-3) {
    padding: 2em 0;
  } */

  .row-3 {
    margin-bottom: 2em;
    @media ${(props) => props.theme.breakpoints.m} {
      padding-top: 0;
    }
    .text-left {
      grid-row: 1 / span 3;
      @media ${(props) => props.theme.breakpoints.m} {
        grid-row: 4;
      }
    }
  }

  .text-left {
    grid-column: 1 / span 6;
    text-align: right;
    display: flex;
    justify-content: center;
    flex-direction: column;
    p {
      text-align: justify;
    }
    @media ${(props) => props.theme.breakpoints.m} {
      text-align: center;
      grid-row: 2;
    }
    .watermark-text {
      font-size: var(--font-hero-size);
      line-height: var(--font-hero-line-height);
      text-align: right;
      font-weight: 900;
      margin-bottom: 0.4em;
      @media ${(props) => props.theme.breakpoints.m} {
        text-align: center;
        font-size: var(--font-herotablet-size);
        line-height: var(--font-herotablet-size-line-height);
      }
    }

    @supports (-webkit-text-stroke: 1px var(--color-light-gray)) {
      .watermark-text {
        -webkit-text-stroke: 1px var(--color-light-gray);
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .image-right-one {
    grid-column: 7 / span 4;
    grid-row: 2 / span 2;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 5;
    }
  }
  .image-right-two {
    grid-column: 10 / span 3;
    grid-row: 1 / span 2;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 4 / span 3;
    }
  }

  .bg-gray {
    background: var(--color-very-light-gray);
  }
`;

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query AboutUsPageQuery {
      sanityAboutUsPage {
        title
        content
        sections {
          imageLeft
          title
          description
          grayBg
          imageLink
          image {
            asset {
              gatsbyImageData(
                width: 650
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        slider {
          asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 650
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            _id
          }
        }
      }
    }
  `);

  const title = data.sanityAboutUsPage.title;
  const content = data.sanityAboutUsPage.content;
  const image =
    data.sanityAboutUsPage.slider[0].asset.localFile.childImageSharp
      .gatsbyImageData;
  const image2 =
    data.sanityAboutUsPage.slider[1].asset.localFile.childImageSharp
      .gatsbyImageData;

  const aboutSections = data.sanityAboutUsPage.sections;

  return (
    <Layout>
      <Seo title="About Us" />
      <TopTitleBar title={title} />
      <AboutUsStyles>
        <MaxWidthSection className="row-3">
          <div className="text-left">
            <h2 className="watermark-text">
              <em>Student Housing</em>
            </h2>
            <p>{content}</p>
          </div>
          <div className="image-right-one">
            <GatsbyImage image={image} alt="Property" />
          </div>
          <div className="image-right-two">
            <GatsbyImage image={image2} alt="Property" />
          </div>
        </MaxWidthSection>
        <FeaturedNews />
        {aboutSections.map((section, i) => (
          <AboutUsSection
            key={i}
            title={section.title}
            content={section.description}
            image={section.image.asset.gatsbyImageData}
            imageLeft={section.imageLeft}
            grayBg={section.grayBg}
            imageLink={section.imageLink}
          />
        ))}
      </AboutUsStyles>
    </Layout>
  );
};

export default AboutUs;
